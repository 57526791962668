import React from 'react';
import { productProps } from '../../helpers/propTypes';
import { TagPlusIcon, TagStarIcon } from '../Images';
import l from '../../helpers/lang';
import Tag from '../tag/Tag';

const addProductTagLabel = (tag) => ({
  ...tag,
  label: l(tag.name),
});

function createProductTags(product) {
  let tags = [];
  if (product.popular) {
    tags.push({
      label: l('product.tag.popular'),
      color: '#cdaf12',
      icon: <TagStarIcon />,
    });
  }

  const productTags = product.tags?.map(addProductTagLabel) || [];
  tags = tags.concat(productTags);

  if (product.group_modifications?.length > 0) {
    tags.push({
      label: l('product.tag.addons'),
      color: '#2aaee4',
      icon: <TagPlusIcon />,
    });
  }

  return tags;
}

function ProductTags({ product }) {
  const tags = createProductTags(product);
  return (
    <div className="d-flex flex-wrap mt-2">
      {tags.map((tag) => (
        <Tag key={tag.label} label={tag.label} color={tag.color} icon={tag.icon} />
      ))}
    </div>
  );
}

ProductTags.propTypes = {
  product: productProps.isRequired,
};

export default ProductTags;
