import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { hexToRgba } from '../../helpers/color';

function Tag({ label, color, icon = undefined }) {
  const tagStyles = {
    color: hexToRgba(color),
    backgroundColor: hexToRgba(color, 0.2),
  };
  return (
    <span
      className={classNames('badge rounded-pill me-2 mb-2 d-flex align-items-center')}
      style={tagStyles}
    >
      {icon && <span className="me-1">{icon}</span>}
      {label}
    </span>
  );
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  icon: PropTypes.element,
};

export default Tag;
