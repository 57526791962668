import _, { findWhere } from 'underscore';
import Storage from './storage';
import { STORAGE_CART, STORAGE_PRODUCTS } from './constants';
import RollbarLogger from './RollbarLogger';

export default class Cart {

  static getCart = () => Storage.get(STORAGE_CART);

  static setCart = (cart) => Storage.set(STORAGE_CART, cart);

    static getProductsCount = (cart, productId) => {
      if (!cart || !cart.products) {
        return {};
      }

      const productsCount = {};

      cart.products.forEach((p) => {
        if (!productsCount[p.productId]) {
          productsCount[p.productId] = 0;
        }

        productsCount[p.productId] += p.count;
      });

      // Если нужно по конкретному товару посчитать
      if (productId) {
        return productsCount[productId] || 0;
      }

      return productsCount;
    };

    /**
     * Возвращаем название модификатора товара или выбранные модификации ТТК разделенные запятой
     *
     * @param {object} dish Товар или ТТК
     * @param {object|number} modification Массив модификаций для ТТК или id модификатора для товара
     * @return {string}
     */
    static getModificationTitle(dish, modification) {
      const isProduct = Number.isInteger(modification);
      if (isProduct) {
        const dishModification = _.findWhere(dish.productModifications, { modificator_id: modification });
        return dishModification.name;
      }

      const modificationGroups = _.values(dish.group_modifications)
        .map((group) => {
          const groupData = group;

          groupData.modifications = _.values(groupData.modifications);
          return groupData;
        });

      // берем все модификаторы из групп
      const modificationsArray = [].concat(...modificationGroups.map((group) => group.modifications));

      return modification.map((mod) => {
        const dishModification = _.findWhere(modificationsArray, { dish_modification_id: Number(mod.m) });

        return dishModification.name + (mod.a > 1 ? ` × ${mod.a}` : '');
      }).join(', ');
    }

    /**
     * Находит позицию товара в корзине
     *
     * @param cart
     * @param productId
     * @param modification
     * @param modificator_id
     * @returns {number|*}
     */
    static getProductIndex = ({
      cart, productId, modification, modificator_id,
    }) => {
      if (!cart) {
        return -1;
      }

      if (modificator_id) {
        return _.findIndex(cart.products, { productId, modificator_id });
      }

      if (modification) {
        return _.findIndex(cart.products, (p) => {
          // Приводим модификации в одинаковую строку для поиска
          const jsonToSearch = JSON.stringify(_.sortBy(modification, 'm'));
          const jsonToCheck = JSON.stringify(_.sortBy(p.modification, 'm'));

          return p.productId === productId && jsonToCheck === jsonToSearch;
        });
      }

      // Самый простой кейс, когда обычный товар
      return _.findIndex(cart.products, { productId });
    };

    // FIXME: avoid mutation
    static calculatePrices = (params) => {
      const { cart } = params;
      const products = Storage.get(STORAGE_PRODUCTS);

      /* eslint-disable no-param-reassign */

      cart.sum = 0;
      cart.count = 0;

      cart.products.forEach((cp) => {
        const product = _.findWhere(products, { product_id: cp.productId });

        cp.price = 0;

        if (cp.modification) {
          const allModifications = _.flatten(product.group_modifications.map((p) => p.modifications));
          cp.modification.forEach((m) => {
            const modification = _.findWhere(allModifications, { dish_modification_id: m.m });
            if (!modification) {
              // TODO: удалить после решения проблемы
              RollbarLogger.warn(
                'calculatePrices error log',
                {
                  allModifications,
                  cart,
                  products,
                },
              );
            }
            cp.price += product.isWeight ? (modification.price * m.a) / 100 : modification.price * m.a;
          });
        }

        if (cp.modificator_id) {
          const modificator = _.findWhere(product.productModifications, { modificator_id: cp.modificator_id });
          cp.price += product.isWeight ? modificator.price / 100 : modificator.price;
        }

        cp.price += product.isWeight ? product.price / 100 : product.price; // Добавили основную стоимость
        cp.sum = cp.price * cp.count;

        cart.sum += cp.sum;
        cart.count += product.isWeight ? cp.count / 100 : cp.count;
      });

      /* eslint-enable no-param-reassign */

      return cart;
    }

    /**
     * @param {Object} cart
     * @returns {Number}
     */
    static getNextCartProductId = (cart) => {
      if (!cart || !cart.products || cart.products.length === 0) {
        return 1;
      }

      return Math.max(...cart.products.map((p) => p.cart_product_id)) + 1;
    }

    /**
     * Добавляем в корзину товар
     *
     * @param cart
     * @param productId
     * @param modification
     * @param modificator_id
     * @param count
     * @returns {{products: []}}
     */
    static addProduct = ({
      cart, productId, modification, modificator_id, count,
    }) => {
      const products = Storage.get(STORAGE_PRODUCTS);
      const product = _.findWhere(products, { product_id: productId });

      let updatedCart = cart || { products: [] };

      if (!product || product.name === undefined) {
        // TODO: удалить после решения проблемы
        RollbarLogger.warn(
          'addProduct error log',
          {
            product,
            productId,
            modification,
            modificator_id,
            products,
            cart,
          },
        );
      }
      updatedCart.products.push({
        productId,
        name: product.name,
        count,
        modification,
        modificator_id,
        cart_product_id: this.getNextCartProductId(updatedCart),
      });

      updatedCart = this.calculatePrices({ cart: updatedCart });

      Storage.set(STORAGE_CART, updatedCart);

      return updatedCart;
    };

    static revertAddition = ({
      productId, modification, modificator_id,
    }) => {
      RollbarLogger.log(
        'Revert add to cart',
        {
          productId,
          modification,
          modificator_id,
        },
      );

      const currentCart = Storage.get(STORAGE_CART);
      const pIndex = this.getProductIndex({
        cart: currentCart, productId, modification, modificator_id,
      });
      currentCart.products.splice(pIndex, 1);
      const updatedCart = this.calculatePrices({ cart: currentCart });
      Storage.set(STORAGE_CART, updatedCart);
    }

    static revertCartEdit = ({ pIndex, delta }) => {
      const currentCart = Storage.get(STORAGE_CART);
      if (currentCart.products[pIndex]) {
        RollbarLogger.log(
          'Revert cart edit',
          {
            pIndex,
            delta,
          },
        );

        let updatedCart = currentCart;
        updatedCart.products[pIndex].count -= delta;
        updatedCart = this.calculatePrices({ cart: currentCart });
        Storage.set(STORAGE_CART, updatedCart);
      }
    }

    /**
     * @param {import('../types').CartProductInfo} productInfo
     * @returns {import('../types').CartData}
     */
    static changeProducts = (productInfo) => {
      const { productId } = productInfo;
      let { count, modification, modificator_id } = productInfo;

      let cart = Storage.get(STORAGE_CART);
      const pIndex = this.getProductIndex({ cart, ...productInfo });

      if (pIndex === -1) {
        return this.addProduct({ cart, ...productInfo });
      }

      const product = _.findWhere(Storage.get(STORAGE_PRODUCTS), { product_id: productId });
      const currentCount = this.getProductsCount(cart, productId);

      // Если текущее кол-во больше нового, то мы удаляем товар
      // С главной страницы мы не присылаем ID модификации поскольку не знаем его
      // Тогда нам нужно самим указать эту модификацию и заполнить кол-во
      if (currentCount > count && (product.productModifications || product.group_modifications) && !modificator_id && !modification) {
        modificator_id = cart.products[pIndex].modificator_id;
        modification = cart.products[pIndex].modification;
        count = cart.products[pIndex].count - (currentCount - count);
      }

      cart.products[pIndex].count = count;

      // Удаляем вообще товар если его сбросили в 0
      if (cart.products[pIndex].count <= 0) {
        cart.products.splice(pIndex, 1);
      }

      cart = this.calculatePrices({ cart });

      Storage.set(STORAGE_CART, cart);

      return cart;
    };

    /**
     * Устанавливаем cart_product_id у товара который только добавили в корзину.
     * Он нужен чтобы потом обновить кол-во товара в корзине.
     *
     * Также устанавливает ID корзины
     *
     * @param cartId
     * @param cartProductId
     * @param productId
     * @param modification
     * @param modificator_id
     */
    static setNewProductId = ({
      cartId, cartProductId, productId, modification, modificator_id,
    }) => {
      const cart = Storage.get(STORAGE_CART);
      const productIndex = this.getProductIndex({
        cart, productId, modification, modificator_id,
      });

      if (!cart) {
        // TODO: удалить после решения проблемы
        RollbarLogger.warn(
          'setNewProductId error log',
          {
            cartId,
            cartProductId,
            productId,
            modification,
            modificator_id,
          },
        );
      }
      // Если мы самый первый товар, то у нас еще нет ID корзины, его тоже запишем
      if (cartId) {
        cart.cartId = cartId;
      }

      if (productIndex !== -1) {
        cart.products[productIndex].cart_product_id = cartProductId;
      }

      Storage.set(STORAGE_CART, cart);
    }

    static changeDishModification = ({ cart_product_id, modification }) => {
      let cart = Storage.get(STORAGE_CART);
      const pIndex = _.findIndex(cart.products, { cart_product_id });

      cart.products[pIndex].modification = modification;

      cart = this.calculatePrices({ cart });
      Storage.set(STORAGE_CART, cart);

      // запоминаем товар, который мы редактировали
      const editedProduct = cart.products[pIndex];
      // убираем из корзины текущий товар для поиска дубликатов
      cart.products.splice(pIndex, 1);
      // ищем в корзине товар с тем же набором модификаторов
      const duplicateIndex = Cart.getProductIndex({ cart, ...editedProduct });
      // если находим дубликат, то удаляем его, а его количество прибавляем к изменяемому продукту
      if (duplicateIndex !== -1) {
        const duplicateProduct = cart.products[duplicateIndex];
        editedProduct.count += duplicateProduct.count;
        duplicateProduct.count = 0;
        Cart.changeProducts(duplicateProduct);
        Cart.changeProducts(editedProduct);
      }
    }

    /**
     * Возвращает итоговую сумму доставки
     *
     * @param cart
     * @param deliveryPrice
     * @param freeDeliveryOrderAmount
     * @returns {number}
     */
    static getDeliveryPrice = ({ cart, deliveryPrice, freeDeliveryOrderAmount }) => {
      if (!deliveryPrice || (freeDeliveryOrderAmount !== -1 && cart.sum >= freeDeliveryOrderAmount)) {
        return 0;
      }

      return deliveryPrice;
    }

    /**
     * Actualize available products and their prices in cart
     * @param {Product[]} products
     */
    static actualizeProducts(products) {
      const cart = this.getCart();

      if (!cart?.count) {
        return;
      }

      // yeah, these methods do mutate the cart :(
      this.removeDeletedProducts(products, cart);
      this.calculatePrices({ cart });

      this.setCart(cart);
    }

    // убираем из корзины удаленные/hidden товары
    static removeDeletedProducts(products, cart) {
      // FIXME: avoid mutation
      cart.products.forEach((cp, i, cartProducts) => {
        const isDeleted = !findWhere(products, { product_id: cp.productId });
        if (isDeleted) {
          cart.count -= cp.count;
          cart.sum -= cp.count * cp.price;
          cartProducts.splice(i, 1);
        }
      });

      return cart;
    }
}
