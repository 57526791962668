import React from 'react';
import PropTypes from 'prop-types';

import { Minus, Plus } from './Images';

function Counter({
  onChangeCount, count, max, className, small, isWeight, testid,
}) {
  const onDecrement = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (isWeight) {
      onChangeCount(count - 100);
    } else {
      onChangeCount(count - 1);
    }
  };

  const onIncrement = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (isWeight) {
      if (max !== 0 && count + 100 > max) return;
      onChangeCount(count + 100);
    } else {
      if (max !== 0 && count + 1 > max) return;
      onChangeCount(count + 1);
    }
  };

  const buttonSize = small ? 2 : 3;

  return (
    <div className={`text-end one-line-text ${className}`} style={{ whiteSpace: 'nowrap' }}>
      {count > 0 && (
        <>
          <div
            className={`d-inline-block rounded-1 bg-primary-lighter p-${buttonSize}`}
            style={{ lineHeight: 0 }}
            aria-label="Reduce"
            data-testid="minus_btn"
            onClick={onDecrement}
          >
            <Minus />
          </div>

          <span className="d-inline-block align-middle text-center" style={{ width: isWeight ? 44 : 30 }}>
            {count}
          </span>
        </>
      )}

      <button
        className={`d-inline-block rounded-1 btn btn-primary border-0 p-${buttonSize}`}
        aria-label="Add"
        data-testid="plus_btn"
        style={{ lineHeight: 0 }}
        onClick={onIncrement}
      >
        <Plus />
      </button>
    </div>
  );
}

Counter.propTypes = {
  onChangeCount: PropTypes.func,
  count: PropTypes.number,
  max: PropTypes.number,
  className: PropTypes.string,
  small: PropTypes.bool,
  isWeight: PropTypes.bool,
};

Counter.defaultProps = {
  className: '',
  onChangeCount: () => {
  },
  count: 0,
  max: 0,
  small: false,
  isWeight: false,
};

export default Counter;
