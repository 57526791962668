import React from 'react';
import Image from 'next/image';
import { isDevelopment } from '../helpers/environment';

const normalizeSrc = (src) => (src.startsWith('/') ? src.slice(1) : src);

const loaderPureOriginal = ({ src }) => src.replace('_big.', '_original.');

const loaderCloudflare = ({ src, width, quality }) => {
  const params = [`width=${width},format=webp`];
  if (quality) {
    params.push(`quality=${quality}`);
  }
  const paramsString = params.join(',');

  if (isDevelopment()) {
    return `${normalizeSrc(src)}`;
  }
  return `https://img.postershop.me/cdn-cgi/image/${paramsString}/${normalizeSrc(src)}`;
};

export const loaderCloudflareOriginal = ({ src, width, quality }) => loaderCloudflare({
  src: loaderPureOriginal({ src }),
  width,
  quality,
});

function ImageWrapper(props) {
  return <Image loader={loaderCloudflare} {...props} />;
}

export function SlideImageWrapper(props) {
  return <Image loader={loaderCloudflareOriginal} {...props} />;
}

export default ImageWrapper;
